<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="stylus">
@import "assets/styles/index.styl"
#app {
  background-image url("./assets/images/bg.png")
  background-repeat no-repeat
  background-size cover
  background-position center top 30px
}
</style>