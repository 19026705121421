import Vue from 'vue'
import * as filters from "./filters.js";
import App from './App.vue'
import router from './router'
import store from './store'
import { VueMaskDirective } from "v-mask";

Vue.config.productionTip = true

Vue.directive("mask", VueMaskDirective);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
