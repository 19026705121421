export default {
  registration_actions (state, data) {
    state.registration_actions = data.data.actions
  },
  questions_data (state, data) {
    state.questions_data = data.data
  },
  setAuthToken (state, token) {
    state.authToken = token
    localStorage.setItem('auth-token', token)
  },
  removeAuthToken (state) {
    state.authToken = null
    localStorage.removeItem('auth-token')
  },
}
