function setAuthToken ({ commit }, { token }) {
  commit('setAuthToken', token)
}

function removeAuthToken ({ commit }) {
  commit('removeAuthToken')
}

export {
  setAuthToken,
  removeAuthToken,
}
