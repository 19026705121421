import axios from "axios";

/**
 * Получение кода подтверждения
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */

async function GET_AUTH_CODE ({ state }, data) {
  return await axios.post(`https://${state.url}/authorizationService/token`, data, {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  })
}

/**
 * Ввод кода подтверждения номера телефона
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function PHONE_NUMBER_CONFIRM({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/PhoneNumber/Confirm`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

export {
  GET_AUTH_CODE,
  PHONE_NUMBER_CONFIRM,
};
