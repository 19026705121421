import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions/index";
import mutations from "./mutations";
// import config from "../configuration"

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  mutations,
  state: {
    url: "galamart.loymax.tech",
    version: "v1.2",
    authToken: null,
    registration_actions: [],
    questions_data: [],
    user: {
      phoneNumber: null,
      code: null,
    },
    config: {
      // вместо пароля отправлять код регистрации, скрыв шаг ввода пароля
      // false - нет
      // true - да
      password_as_confirmation_code: true,
      url_redirect: "", // url для перенаправления после регистрации
      redirect_timer: 5000, // время после которого просиходит редирект в мс
      finish_text: "Поздравляем! Вы успешно прошли регистрацию.", // текст в конце регистрации
      code_timer: 120, // время таймера отправки кода в секундах
      contact_center: "78005050909", // номер контактного центра
      // API-ключ DaData
      dadata_token: "b8aa75aaaa42c18ca78fd763868cd0edbad34b56",
      // шаг ввода E-mail
      // 0 - не показывать шаг
      // 1 - показывать, но можно пропустить
      // 2 - показывать, ввод обязательный
      email: 1,
      // --------------------------------
      // возможность пропустить подтверждение email
      // 0 - нельзя пропустить
      // 1 - можно пропустить
      email_confirm: 1,
      // --------------------------------
      // шаг ввода физической карты
      // 0 - не показывать шаг
      // 1 - показывать, но можно пропустить
      // 2 - показывать, ввод обязательный
      card: 1,
      // --------------------------------
      // Выпустить виртуальную карту
      // 0 - нет
      // 1 - да
      // 2 - выпустить, если не привязали физическую карту
      virtual_card: 2,
      // --------------------------------
      // массив с localName видимых вопросов в анкете, перечислять через запятую
      visible_questions: [
        "LastName",
        "FirstName",
        "PatronymicName",
        "Birthday",
        "Sex",
        "Address",
      ],
      // ---------------------------------
      // массив с localName обязательных вопросов в анкете
      required_questions: ["LastName", "FirstName", "Birthday", "Sex"],
      // скрытый вопрос для подтверждения что адрес введен
      aifi_question: {
        fixedAnswerIds: null,
        questionGroupId: 2, // id группы вопросов
        questionId: 43, // id вопроса
        tag: "0",
        value: null,
      }
    },
    codes: {
      registration: {
        BEGIN_REGISTRATION_CODE: 1,
        PHONE_CONFIRM_CODE: 2,
        PHONE_CHANGE_CODE: 3,
        CARD_BINDING_CODE: 4,
        EMAIL_CONFIRM_CODE: 5,
        PASSWORD_CHANGE_CODE: 6,
        QUESTIONS_CODE: 7,
        FINISH_CODE: 8,
      },
      bonus_cards: {
        CHOICE_CODE: 1,
        AUTH_CONFIRM_CODE: 2,
        CARD_SET_CODE: 3,
        QUESTIONS_CODE: 4,
        EMAIL_CHANGE: 5,
        FINISH_CODE: 6,
      },
    }
  }
})
