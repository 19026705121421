import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("../views/home/Home.vue")
  },
  {
    path: '/bonuscards',
    name: 'bonuscards',
    component: () => import("../views/bonus_cards/index.vue")
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import("../views/Terms.vue")
  },
  {
    path: '/personaldata',
    name: 'personaldata',
    component: () => import("../views/PersonalData.vue")
  },
  {
    path: '/confirmemail',
    name: 'confirmemail',
    component: () => import("../views/ConfirmEmail.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
