import axios from "axios";

/**
 * Запрос на начало регистрации
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */

async function BEGIN_REGISTRATION({ state }, data) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/Registration/BeginRegistration`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
}

/**
 * Получение списка шагов регистрации
 * @param state
 * @param commit
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function REGISTRATION_ACTIONS({ state, commit }, { token }) {
  await axios
    .get(`https://${state.url}/publicapi/${state.version}/User/RegistrationActions`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(({ data }) => {
      commit("registration_actions", data)
    })
}

/**
 * Получение информации о подтверждаемом номере телефона
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function PHONE_NUMBER({ state }, { token }) {
  return await axios.get(`https://${state.url}/publicapi/${state.version}/User/PhoneNumber`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Ввод кода подтверждения номера телефона
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function PHONE_NUMBER_CONFIRM({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/PhoneNumber/Confirm`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Повторная отправка кода подтверждения номера телефона
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function SEND_CONFIRM_CODE({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/PhoneNumber/SendConfirmCode`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Получение карт
 * @param state
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function GET_CARD({ state }, { token }) {
  return await axios.get(`https://${state.url}/publicapi/${state.version}/Cards`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Привязка карты
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function SET_CARD({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/Cards/Set`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Получение список пакетных загрузок паролей
 * @param state
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function GET_PASSWORD_BATCH_UPLOAD({ state }, { token }) {
  return await axios.get(`https://${state.url}/publicapi/${state.version}/User/Password/BatchUpload`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Изменение пароля
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function PASSWORD_SET({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Password/Set`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Получение текущего E-mail
 * @param state
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function GET_EMAIL({ state }, { token }) {
  return await axios.get(`https://${state.url}/publicapi/${state.version}/User/Email`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Привязка электронной почты
 * @param state
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function SET_EMAIL({ state }, { token, data }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Email`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Ввод кода подтверждения E-mail
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function EMAIL_CONFIRM({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Email/Confirm`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Повторная отправка кода подтверждения на E-mail
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function SEND_EMAIL_CODE({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Email/SendConfirmCode`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Получение вопросов анкеты
 * @param state
 * @param commit
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_QUESTIONS({ state, commit }, { token }) {
  await axios
    .get(`https://${state.url}/publicapi/${state.version}/User/Questions`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params: {
        filter: "onlyRequired"
      }
    })
    .then(({ data }) => {
      commit("questions_data", data)
    })
}

/**
 * Запрос принятия договора оферты
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function ACCEPT_TENDER_OFFER({ state }, { token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/AcceptTenderOffer`, {}, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Запрос согласия на получение рассылок
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function SUBSCRIPTIONS_CONFIRM({ state }, { token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Subscriptions/Confirm`, {}, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Отправка ответов анкеты
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_ANSWERS({ state }, { data, token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/User/Answers`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Завершение регистрации
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function TRY_FINISH_REGISTRATION({ state }, { token }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/Registration/TryFinishRegistration`, {}, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Запрос на получение подписок пользователя
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function USER_SUBSCRIPTIONS({ state }, { token }) {
  return await axios.get(`https://${state.url}/publicapi/${state.version}/User/Subscriptions`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Выпуск вируальной карты
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function EMIT_VIRTUAL({ state }, { token }) {
  return await axios.put(`https://${state.url}/publicapi/${state.version}/Cards/EmitVirtual`, {}, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

/**
 * Подтверждение почты
 * @param state
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function LINK_CONFIRM({ state }, { confirmCode, personID }) {
  return await axios.post(`https://${state.url}/publicapi/${state.version}/user/email/linkConfirm`, {
    confirmCode,
    personID
  }, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
}

export {
  BEGIN_REGISTRATION,
  REGISTRATION_ACTIONS,
  PHONE_NUMBER,
  PHONE_NUMBER_CONFIRM,
  SEND_CONFIRM_CODE,
  EMAIL_CONFIRM,
  SEND_EMAIL_CODE,
  GET_CARD,
  SET_CARD,
  GET_PASSWORD_BATCH_UPLOAD,
  PASSWORD_SET,
  GET_EMAIL,
  SET_EMAIL,
  GET_QUESTIONS,
  ACCEPT_TENDER_OFFER,
  SUBSCRIPTIONS_CONFIRM,
  POST_ANSWERS,
  TRY_FINISH_REGISTRATION,
  USER_SUBSCRIPTIONS,
  EMIT_VIRTUAL,
  LINK_CONFIRM,
};
